import { z } from 'zod';

// Schémas de base
const RegulationPropagationDtoSchema = z.object({
    endStopRank: z.number(),
    endServiceId: z.string().optional(),
});

// Schémas pour chaque type d'action
const StopCancellationActionDtoSchema = z.object({
    actionType: z.literal('stopCancellation'),
    propagation: RegulationPropagationDtoSchema.optional(),
    isCancelled: z.boolean(),
});

const PlatformChangeActionDtoSchema = z.object({
    actionType: z.literal('platformChange'),
    newStopId: z.string(),
    newStopName: z.string().optional(),
});

const MessageActionDtoSchema = z.object({
    actionType: z.literal('passengerInfoMessage'),
    messageTemplateId: z.string().optional(),
    messageName: z.string().optional(), // Message actif
    messageId: z.string().optional(), // Message actif
    startDate: z.string().optional(),
    endDate: z.string().optional(),
    propagation: RegulationPropagationDtoSchema.optional(),
    /**
     * Le format est une string de 7 booléens, un pour chaque jour de la semaine, commençant par lundi.
     */
    daysOfWeek: z.string().optional(),
    isActive: z.boolean(),
});

const DepartureOrderActionDtoSchema = z.object({
    actionType: z.literal('departureOrder'),
    durationDelta: z.string(), // time-duration format
});

const DriverMessageActionDtoSchema = z.object({
    actionType: z.literal('driverMessage'),
    message: z.string(),
});

const ArrivalDelayActionDtoSchema = z.object({
    actionType: z.literal('arrivalDelay'),
    durationDelta: z.string(), // time-duration format
});

const UnspecifiedDelayActionDtoSchema = z.object({
    actionType: z.literal('unspecifiedDelay'),
    isActive: z.boolean(),
});

const ModifiedDepartureStopActionDtoSchema = z.object({
    actionType: z.literal('modifiedDepartureStop'),
    isDeparture: z.boolean(),
});

const ModifiedArrivalStopActionDtoSchema = z.object({
    actionType: z.literal('modifiedArrivalStop'),
    isArrival: z.boolean(),
});

export const ErrorDtoSchema = z.object({
    success: z.boolean(),
    error: z.string(),
});

export const RegulationActionResponseDtoSchema = z.object({
    success: z.boolean(),
    message: z.string(),
});

// Schéma pour RegulationActionDto (union des différents types d'actions)
export const RegulationActionDtoSchema = z.discriminatedUnion('actionType', [
    StopCancellationActionDtoSchema,
    PlatformChangeActionDtoSchema,
    MessageActionDtoSchema,
    DepartureOrderActionDtoSchema,
    DriverMessageActionDtoSchema,
    ArrivalDelayActionDtoSchema,
    UnspecifiedDelayActionDtoSchema,
    ModifiedDepartureStopActionDtoSchema,
    ModifiedArrivalStopActionDtoSchema,
]);

// Schéma pour PostRegulationActionDto
export const PostRegulationActionDtoSchema = z.object({
    tripId: z.string(),
    operationDate: z.string(),
    stopRank: z.number(),
    regulationActions: z.array(RegulationActionDtoSchema),
});

// Types inférés à partir des schémas Zod
export type RegulationPropagationDto = z.infer<typeof RegulationPropagationDtoSchema>;
export type StopCancellationActionDto = z.infer<typeof StopCancellationActionDtoSchema>;
export type PlatformChangeActionDto = z.infer<typeof PlatformChangeActionDtoSchema>;
export type MessageActionDto = z.infer<typeof MessageActionDtoSchema>;
export type DepartureOrderActionDto = z.infer<typeof DepartureOrderActionDtoSchema>;
export type DriverMessageActionDto = z.infer<typeof DriverMessageActionDtoSchema>;
export type ArrivalDelayActionDto = z.infer<typeof ArrivalDelayActionDtoSchema>;
export type UnspecifiedDelayActionDto = z.infer<typeof UnspecifiedDelayActionDtoSchema>;
export type ModifiedDepartureStopActionDto = z.infer<typeof ModifiedDepartureStopActionDtoSchema>;
export type ModifiedArrivalStopActionDto = z.infer<typeof ModifiedArrivalStopActionDtoSchema>;
export type RegulationActionDto = z.infer<typeof RegulationActionDtoSchema>;
export type PostRegulationActionDto = z.infer<typeof PostRegulationActionDtoSchema>;
export type ErrorActionDto = z.infer<typeof ErrorDtoSchema>;
export type RegulationActionResponseDto = z.infer<typeof RegulationActionResponseDtoSchema>;
