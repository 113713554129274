import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SchemaValidatorService } from '@shared/services/schema-validator.service';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
    GetParameterDto,
    GetParameterDtoResponseSchema,
    PostParameterDto,
    PostParameterResponseDto,
    PostParameterResponseDtoSchema,
    RegulationActionParameters,
} from './parameters.model';
import { ErrorActionDto, ErrorDtoSchema } from '@models/models';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';

@Injectable({
    providedIn: 'root',
})
export class ParametersService {
    #http = inject(HttpClient);
    #schemaValidator = inject(SchemaValidatorService);
    #runtimeConfigService = inject(RuntimeConfigService);
    #parametersApiUrl = computed(() => `${this.#runtimeConfigService.apiUrl()}/parameters`);
    #variableName = 'sae-parameters';

    parameters = signal<RegulationActionParameters>(this.#createDefaultParameters());

    /**
     * Récupère les paramètres de régulation depuis l'API pour les stocker dans le signal local
     */
    async updateParametersFromApi(): Promise<void> {
        const params = new HttpParams().set('variableName', this.#variableName);

        const response = await firstValueFrom(
            this.#http.get<GetParameterDto | ErrorActionDto>(this.#parametersApiUrl(), { params }).pipe(
                map((data) => this.#schemaValidator.validate(GetParameterDtoResponseSchema.or(ErrorDtoSchema), data)),
                catchError((error) => this.#handleError(error)),
            ),
        );

        if (this.#isSuccess(response) && response.variableValue) {
            const parsedObject = JSON.parse(response.variableValue) as RegulationActionParameters;
            this.parameters.set(parsedObject);
        }
    }

    async postParameters(regulationActionParameters: RegulationActionParameters): Promise<{
        success: boolean;
        message: string;
    }> {
        const body: PostParameterDto = {
            variableName: this.#variableName,
            variableValue: JSON.stringify(regulationActionParameters),
        };
        const response = await firstValueFrom(
            this.#http.post<PostParameterResponseDto | ErrorActionDto>(this.#parametersApiUrl(), body).pipe(
                map((data) => this.#schemaValidator.validate(PostParameterResponseDtoSchema.or(ErrorDtoSchema), data)),
                catchError((error) => this.#handleError(error)),
            ),
        );

        if (this.#isSuccess(response)) {
            const parsedObject = JSON.parse(response.variableValue) as RegulationActionParameters;
            this.parameters.set(parsedObject);
            return {
                success: true,
                message: 'Modifications enregistrées avec succès',
            };
        } else {
            return {
                success: false,
                message: response.error,
            };
        }
    }

    #isSuccess(result: ErrorActionDto | GetParameterDto | PostParameterResponseDto): result is GetParameterDto | PostParameterResponseDto {
        return !('error' in result);
    }

    #handleError(error: unknown): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Une erreur est survenue lors de la communication avec le serveur'));
    }

    #createDefaultParameters(): RegulationActionParameters {
        const defaultValue = true;
        return {
            arrivalDelay: defaultValue,
            departureOrder: defaultValue,
            driverMessage: defaultValue,
            modifiedArrivalStop: defaultValue,
            modifiedDepartureStop: defaultValue,
            passengerInfoMessage: defaultValue,
            platformChange: defaultValue,
            stopCancellation: defaultValue,
            unspecifiedDelay: defaultValue,
        };
    }
}
