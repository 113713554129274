import { z } from 'zod';

export const GetParameterDtoResponseSchema = z.object({
    variableName: z.string(),
    variableValue: z.string(),
});

export const PostParameterDtoSchema = z.object({
    variableName: z.string(),
    variableValue: z.string(),
});

export const PostParameterResponseDtoSchema = z.object({
    success: z.boolean(),
    variableName: z.string(),
    variableValue: z.string(),
});

export type GetParameterDto = z.infer<typeof GetParameterDtoResponseSchema>;
export type PostParameterDto = z.infer<typeof PostParameterDtoSchema>;
export type PostParameterResponseDto = z.infer<typeof PostParameterResponseDtoSchema>;

export interface RegulationActionParameters {
    arrivalDelay: boolean;
    departureOrder: boolean;
    driverMessage: boolean;
    modifiedArrivalStop: boolean;
    modifiedDepartureStop: boolean;
    passengerInfoMessage: boolean;
    platformChange: boolean;
    stopCancellation: boolean;
    unspecifiedDelay: boolean;
}
